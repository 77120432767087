import React from 'react';
import { Container, Line, Dot, Paragraph, Section } from './Styles';

const About = () => {
	return (
		<Section white>
			<Container>
				<Paragraph>
					<Line></Line>
					<Dot></Dot>
					Hello there! I'm Lisen, a detail-oriented frontend developer with passion for UI design
					and IT operations.
				</Paragraph>
			</Container>
		</Section>
	);
};

export default About;
