import styled, { css } from 'styled-components';

export const Section = styled.section`
	background-color: var(--light-grey);
	${(props) =>
		props.white &&
		css`
			background: var(--white);
		`};
`;

export const Container = styled.div`
	width: 375px;
	margin: 0 auto;
	padding: 2rem 1rem;
	@media (min-width: 768px) {
		width: 700px;
	}
	@media (min-width: 1100px) {
		width: 1000px;
	}
`;

export const Hero = styled.header`
	background-image: url(./assets/hero5.jpg);
	background-size: cover;
	/* background: linear-gradient(
		135deg,
		rgba(18, 130, 190, 1) 0%,
		rgba(161, 205, 230, 1) 50%,
		rgba(223, 233, 246, 1) 100%
	); */
	height: 520px;
	color: var(--white);
`;

export const HeaderText = styled.div`
	padding-top: 4rem;
	text-shadow: 1px 1px 1px var(--primary);
`;

export const HeaderTitle = styled.h1`
	font-weight: 700;
	font-size: 3rem;
	line-height: 1;
	text-transform: uppercase;
	@media (min-width: 768px) {
		font-size: 4rem;
	}
`;

export const HeaderName = styled.h2`
	font-weight: 500;
	font-size: 1.25rem;
	letter-spacing: 1px;
	text-transform: uppercase;
	@media (min-width: 768px) {
		font-size: 1.5rem;
		line-height: 1.5;
	}
`;

export const ProfileContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	padding-top: 100px;
	@media (min-width: 768px) {
		padding-top: 100px;
	}
`;

export const Profile = styled.img`
	height: 180px;
	width: 180px;
	border-radius: 50%;
	border: 2px solid var(--light-grey);
	@media (min-width: 768px) {
		transform: scale(1.3);
	}
`;

export const Line = styled.span`
	border: 1px solid var(--secondary);
	width: 40px;
	display: inline-block;
	margin-bottom: 4px;
`;

export const Dot = styled.span`
	height: 10px;
	width: 10px;
	background-color: var(--secondary);
	border-radius: 50%;
	display: inline-block;
	margin-right: 10px;
`;

export const Paragraph = styled.p`
	font-size: 1.125rem;
	line-height: 1.5;
`;

export const SectionTitle = styled.h2`
	font-weight: 700;
	font-size: 1.5rem;
	color: var(--white);
	background-color: var(--secondary);
	display: table;
	text-align: center;
	padding: 0.25rem 1rem;
	margin: 1rem auto;
	text-transform: uppercase;
	@media (min-width: 768px) {
		font-size: 1.75rem;
	}
`;

export const SectionSubtitle = styled.h2`
	font-weight: 700;
	font-size: 1.5rem;
	color: var(--primary);
	margin: 1rem auto;
	text-transform: uppercase;
	text-align: center;
	@media (min-width: 768px) {
		font-size: 1.75rem;
	}
`;

export const Project = styled.article`
	margin: 2rem 0;
	@media (min-width: 768px) {
		margin: 0;
		${(props) =>
			props.other &&
			css`
				margin-bottom: 2rem;
			`};
	}
`;

export const ProjectTitle = styled.h3`
	color: var(--primary);
	font-weight: 700;
	font-size: 1.25rem;
	text-transform: uppercase;
	text-align: left;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
`;

export const TagWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 0.5rem;
	margin-top: 0.5rem;
`;

export const Tag = styled.p`
	color: var(--primary);
	border: 2px solid;
	line-height: 1;
	font-weight: 500;
	padding: 0.25rem 0.5rem;
`;

export const Link = styled.a`
	color: var(--primary);

	&:hover {
		color: var(--secondary);
	}
`;
