import React from 'react';
import { IconContainer, IconWrapper, Icon } from '../components/Socials';

const Footer = (footer) => {
	return (
		<footer>
			<h2>Contact</h2>
			<p>Lisen Lundgren</p>
			<a href='mailto:lisolundgren@gmail.com' class='email'>
				lisolundgren@gmail.com
			</a>
			<IconContainer footer>
				<a href='https://www.linkedin.com/in/lisolu/' target='_blank' rel='noreferrer'>
					<IconWrapper>
						<Icon className='fa-brands fa-linkedin-in'></Icon>
					</IconWrapper>
				</a>
				<a href='https://github.com/mistscale' target='_blank' rel='noreferrer'>
					<IconWrapper>
						<Icon className='fa-brands fa-github'></Icon>
					</IconWrapper>
				</a>
			</IconContainer>
		</footer>
	);
};

export default Footer;
